* {
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}

body {
  font-family: "Arial", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(90deg, #dbdbf4 0%, #fcd7e0 100%);
}

p,
label,
button {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

h2 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-style: italic;
  color: #222222;
}

div::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.scroll-hidden {
  overflow: hidden;
}

@media all and (max-width: 1920px) {
  p,
  label,
  button {
    font-size: 13px;
  }

  h2 {
    font-size: 22px;
  }
}

@media all and (max-width: 768px) {
  p,
  label,
  button {
    font-size: 12px;
  }
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Rubik;
  font-weight: 400;
  src: url(./assets/fonts/Rubik-Regular.ttf);
}

@font-face {
  font-family: Rubik;
  font-weight: 700;
  src: url(./assets/fonts/Rubik-Regular.ttf);
}

@font-face {
  font-family: "Chakra Petch";
  src: url(./assets/fonts/ChakraPetch-Medium.ttf);
}

@font-face {
  font-family: Crenzo;
  src: url(./assets/fonts/CRENZO.ttf);
}

@font-face {
  font-family: "DM Serif Text";
  src: url(./assets/fonts/DMSerifText-Regular.ttf);
}

@font-face {
  font-family: Horizon;
  src: url(./assets/fonts/Horizon-regular.ttf);
}

@font-face {
  font-family: "Kaisei HarunoUmi";
  src: url(./assets/fonts/KaiseiHarunoUmi-Bold.ttf);
}

@font-face {
  font-family: MPLUS1p;
  src: url(./assets/fonts/MPLUS1p-Regular.ttf);
}

@font-face {
  font-family: "Promesh";
  src: url(./assets/fonts/PROMESHStitch-Regular.ttf);
}

@font-face {
  font-family: Ridgeline201;
  src: url(./assets/fonts/Ridgeline201-Regular.otf);
}

@font-face {
  font-family: "Masque";
  src: url(./assets/fonts/MASQUE__.ttf);
}
